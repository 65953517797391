import styled from "@emotion/styled"
import { GatsbyLinkProps } from "gatsby"
import React from "react"
import { createStandardGradient } from "../../theme/theme"
import { PlainLink } from "../PlainLink/PlainLink"
import { getCategory, insightMenuItems } from "./insightsMenu.utils"

export const InsightsMenu: React.FC = () => {
  const url =
    typeof window !== "undefined"
      ? window.location.pathname
      : insightMenuItems[0].link
  const category = getCategory(url)

  return (
    <nav aria-label="Insights Menu">
      <MenuList>
        {insightMenuItems.map((menuItem) => (
          <MenuItem active={category === menuItem.link} key={menuItem.link}>
            <MenuLink
              active={category === menuItem.link}
              to={`/insights/${menuItem.link}`}
            >
              {menuItem.text}
            </MenuLink>
          </MenuItem>
        ))}
      </MenuList>
    </nav>
  )
}

const MenuList = styled.ul({
  display: "flex",
  listStyleType: "none",
  gap: "1em",
  padding: "0",
  overflow: "auto",
  whiteSpace: "nowrap",
})

const MenuItem = styled.li<{ active: boolean }>(({ active, theme }) => ({
  padding: "10px 0px",
  borderBottom: active ? " 2px solid" : "none",
  borderImage: createStandardGradient(
    theme.palette.primary.main,
    theme.palette.primary.light
  ),
  borderImageSlice: 1,
}))

const MenuLink = styled(PlainLink)<GatsbyLinkProps<any> & { active: boolean }>(
  ({ theme, active }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontWeight: active ? 600 : 400,
    color: active ? "#000000" : "rgba(0,0,0,0.5)",
  })
)

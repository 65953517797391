import styled from "@emotion/styled"
import { PageProps } from "gatsby"
import { getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image"
import React from "react"
import { BlogSummaryCard } from "../components/BlogSummary/BlogSummaryCard"
import { InsightsMenu } from "../components/InsightsMenu/InsightsMenu"
import { Layout } from "../components/Layout/Layout"
import { BasePageData } from "../components/Layout/layout.hocs"
import {
  getFooterProps,
  getHeaderProps,
} from "../components/Layout/layout.utils"
import { Paper } from "../components/Paper/Paper"
import { PlainLink } from "../components/PlainLink/PlainLink"
import { Typography } from "../components/Typography/Typography"
import { breakpoint } from "../theme/theme"
import { getSlugFromTitle } from "../utils/slug"

interface Insight {
  fileAbsolutePath: string
  frontmatter: {
    title: string
    category: string
    summary: string
    featuredImage: ImageDataLike
  }
}

interface InsightCategoryData extends BasePageData {
  insights: {
    nodes: Array<Insight>
  }
}

type InsightCategoryProps = PageProps<InsightCategoryData>

const getCategoryInsights = (insights: Array<Insight>, category: string) => {
  const filteredInsights =
    category === "All Posts"
      ? insights
      : insights.filter((insight) => insight.frontmatter.category === category)
  return filteredInsights.map((insight) => {
    const image = process.env.STORYBOOK
      ? (insight.frontmatter.featuredImage as IGatsbyImageData)
      : getImage(insight.frontmatter.featuredImage)
    if (!image) {
      throw new Error(
        `Could not find image for insight ${insight.frontmatter.title}`
      )
    }

    return {
      ...insight.frontmatter,
      featuredImage: image,
    }
  })
}
export const InsightsCategory: React.FC<InsightCategoryProps> = (props) => {
  const { name: category } = props.pageContext as { name: string; slug: string }
  const siteData = props.data.site.siteMetadata
  const headerProps = props.data.header
  const footerProps = props.data.footer
  const insights = getCategoryInsights(props.data.insights.nodes, category)

  return (
    <Layout
      title={`${category} | ${siteData.title}`}
      description={siteData.description}
      header={getHeaderProps(headerProps, "light")}
      footer={getFooterProps(footerProps)}
    >
      <MaxWidthContainer>
        <InsightsMenu />
      </MaxWidthContainer>
      <Paper color="blue" gradient>
        <TitleSection>
          <PageTitle variant="h1">{category}</PageTitle>
        </TitleSection>
      </Paper>
      <MaxWidthContainer>
        {insights.length > 0 ? (
          <InsightsContainer>
            {insights.map((insight) => {
              const link = `/insights/${getSlugFromTitle(insight.title)}`
              return (
                <BlogSummaryCard
                  key={insight.title}
                  category={insight.category}
                  coverImage={insight.featuredImage}
                  description={insight.summary}
                  title={insight.title}
                  link={link}
                />
              )
            })}
          </InsightsContainer>
        ) : (
          <FallbackTextContainer>
            <Typography variant="p">
              Currently, there are no insights in this category. Go back to{" "}
              <PlainLink to="/insights">Insights</PlainLink>.
            </Typography>
          </FallbackTextContainer>
        )}
      </MaxWidthContainer>
    </Layout>
  )
}

const MaxWidthContainer = styled("div")({
  margin: "0 auto",
  padding: "0 2em",
  [breakpoint("lg")]: {
    maxWidth: "1200px",
  },
})

const TitleSection = styled(MaxWidthContainer)({
  padding: "3em 2em 2em",
  width: "100%",
  [breakpoint("sm")]: {
    padding: "8em 2em 4em",
  },
})

const PageTitle = styled(Typography)({
  fontSize: "32px",
  [breakpoint("sm")]: {
    fontSize: "64px",
  },
})

const InsightsContainer = styled(MaxWidthContainer)({
  padding: "4em 2em",
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "2em",
  [breakpoint("md")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
})

const FallbackTextContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  padding: "8em 0",
})

import { graphql } from "gatsby"
import { InsightsCategory } from "../InsightsCategory/InsightsCategory"

export const query = graphql`
  query CategoryQuery {
    insights: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "netlify/blog/" } }
    ) {
      nodes {
        fileAbsolutePath
        frontmatter {
          title
          category
          summary
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    site {
      ...SiteFragment
    }
    header: markdownRemark(fileAbsolutePath: { regex: "/netlify/header.md/" }) {
      ...HeaderFragment
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/netlify/footer.md/" }) {
      ...FooterFragment
    }
  }
`
export default InsightsCategory
